import Slide from "./Slide";
import Features from "./Features";
import Owner from "./Owner";
import Header from "./Header";
import Footer from "./Footer";
const Home = () => {
  return (
    <>
      <Header />
      <Slide />
      <Features />
      <Owner />
      <Footer />
    </>
  );
};
export default Home;
