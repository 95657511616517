const Features = () => {
  return (
    <>
      <>
        {/* Features Section */}
        <section id="features">
          <h2 className="section-title">Our Features</h2>
          <div className="feature-container container">
            <div
              data-aos="fade-right"
              data-aos-delay="100"
              className="feature-item"
            >
              <div className="card">
                <div className="slide slide1">
                  <div className="content">
                    <div className="icon vouches">
                      <i className="fa-solid fa-ticket" aria-hidden="true" />
                      <h3>Vouches</h3>
                    </div>
                  </div>
                </div>
                <div className="slide slide2 vouches">
                  <div className="content">
                    <p>
                      This bot was made to help tackle scammers in marketplaces.
                      Vouch Bot has employed many reputable members to
                      accept/deny any vouches made.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-down"
              data-aos-delay="200"
              className="feature-item"
            >
              <div className="card">
                <div className="slide slide1">
                  <div className="content">
                    <div className="icon verification">
                      <i
                        className="fa-solid fa-fingerprint"
                        aria-hidden="true"
                      />
                      <h3>Verification</h3>
                    </div>
                  </div>
                </div>
                <div className="slide slide2 verification">
                  <div className="content">
                    <p>
                      The staff of our bot with the ability to verify the
                      vouches, add vouches to users if the user not give proof
                      for verification the vouches will declined by our staff
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-delay="300"
              className="feature-item"
            >
              <div className="card">
                <div className="slide slide1">
                  <div className="content">
                    <div className="icon secured">
                      <i
                        className="fa-solid fa-shield-halved"
                        aria-hidden="true"
                      />
                      <h3>Secured</h3>
                    </div>
                  </div>
                </div>
                <div className="slide slide2 secured">
                  <div className="content">
                    <p>
                      All your data in this bot is secured and encrypted. Feel
                      free to use our bot. Any kind of quries related data then
                      you can ask us.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};
export default Features;
