import { useParams, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getUser, errorHandler } from "../helpers/auth";

const Token = () => {
  const [userData, setUserData] = useState("");
  const { access_token } = useParams();
  // console.log(access_token);
  if (access_token) {
    localStorage.setItem("access_token", access_token);
  }
  const getuser = async () => {
    try {
      const user = await getUser(access_token);
      // // console.log(user.data);
      if (user.success) {
        localStorage.setItem("user_id", user.user.id);
        localStorage.setItem("user", JSON.stringify(user.user));
        setUserData(user.user);
      }
    } catch (error) {
      errorHandler(error);
    }
  };
  // console.log(userData);
  useEffect(() => {
    getuser();
  }, []);
  return (
    <>
      <h1> redirecting...</h1>
      {!access_token ? <Navigate to={`/login`} /> : null}
      {access_token && userData ? <Navigate to={`/dashboard`} /> : null}
    </>
  );
};
export default Token;
