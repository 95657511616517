const Slide = () => {
  return (
    <>
      <section className="container" id="home">
        <div className="home-left">
          <div className="home-text">
            <h2>
              <span className="text-muted">Best Trending</span> <br />
              Discord Bot <span className="highlight">Reco</span>
            </h2>
            <div className="home-buttons">
              <a
                data-aos="fade-right"
                data-aos-delay="100"
                href="https://discord.com/api/oauth2/authorize?client_id=996375131591024690&permissions=140794710080&scope=applications.commands%20bot"
                className="btn invite"
              >
                {" "}
                Invite
              </a>
              <a
                data-aos="fade-right"
                data-aos-delay="200"
                href="https://top.gg/bot/996375131591024690?s=05d5dae714c6a"
                className="btn vote"
              >
                {" "}
                Vote
              </a>
              <a
                data-aos="fade-right"
                data-aos-delay="300"
                href="https://discord.gg/recobot"
                className="btn join"
              >
                Join Server
              </a>
            </div>
          </div>
        </div>
        <div className="home-right loading">
          <img src="/assets/logo.png" alt="brand-logo" loading="lazy" />
          <div className="loading">
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
      </section>
    </>
  );
};

export default Slide;
