import "./dashboard.css";
import { useState, useEffect } from "react";
import { NavLink, Navigate } from "react-router-dom";
import { getUser, errorHandler } from "../helpers/auth";
import logo from "../assets/logo.png";
import { FaUserAlt, FaServer, FaPowerOff } from "react-icons/fa";
import { RiTaskFill } from "react-icons/ri";
import { AiFillHome } from "react-icons/ai";
const Dashboard = ({ Content }) => {
  const [userData, setUserData] = useState("");
  const [sidebarToggle, setSidebarToggle] = useState(false);
  function toggleSidebar() {
    setSidebarToggle(!sidebarToggle);
  }
  const access_token = localStorage.getItem("access_token");
  // // // // // // // console.log(access_token);
  const getuser = async () => {
    try {
      const user = await getUser(access_token);
      // // // // // // // // console.log(user.data);
      if (user.success) {
        setUserData(user.user);
        localStorage.setItem("user_id", user.user.id);
        localStorage.setItem("user", JSON.stringify(user.user));
      }
    } catch (error) {
      errorHandler(error);
    }
  };
  // // // // // // // console.log(userData);
  useEffect(() => {
    getuser();
  }, []);
  const SidebarOptions = () => {
    return (
      <>
        <NavLink
          onClick={toggleSidebar}
          to={`/dashboard/profile`}
          className={(navdata) =>
            navdata.isActive ? `sidebar-list-item active` : `sidebar-list-item`
          }
        >
          <span>
            <FaUserAlt /> Profile
          </span>
        </NavLink>
        <NavLink
          onClick={toggleSidebar}
          to={`/dashboard/vouches`}
          className={(navdata) =>
            navdata.isActive ? `sidebar-list-item active` : `sidebar-list-item`
          }
        >
          <span>
            <RiTaskFill /> Vouch
          </span>
        </NavLink>
        <NavLink
          onClick={toggleSidebar}
          to={`/dashboard/servers`}
          className={(navdata) =>
            navdata.isActive ? `sidebar-list-item active` : `sidebar-list-item`
          }
        >
          <FaServer /> Servers
        </NavLink>
        <a onClick={toggleSidebar} href="/" className="sidebar-list-item">
          <AiFillHome /> Home
        </a>
        <NavLink
          onClick={toggleSidebar}
          to={`/logout`}
          className={(navdata) =>
            navdata.isActive
              ? `sidebar-list-item active`
              : `sidebar-list-item log_out`
          }
        >
          <FaPowerOff /> Log out
        </NavLink>
      </>
    );
  };
  return (
    <>
      {!access_token ? <Navigate to={`/login`} /> : null}
      <div className="app-container">
        <div className="sidebar-btn" onClick={toggleSidebar}>
          <i class="fa-solid fa-bars"></i>
        </div>
        <div
          className={
            sidebarToggle ? "sidebar active glass___one" : "sidebar glass___one"
          }
        >
          <div className="sidebar-header">
            <div className="app-icon">
              <img src={logo} alt="" />
            </div>
          </div>
          <ul className="sidebar-list">
            <SidebarOptions />
          </ul>
          <div className="account-info">
            <div className="account-info-picture">
              <img
                src={
                  userData
                    ? `https://cdn.discordapp.com/avatars/${userData.id}/${userData.avatar}.png`
                    : "https://images.unsplash.com/photo-1527736947477-2790e28f3443?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTE2fHx3b21hbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60"
                }
                alt="Account"
              />
            </div>
            <div
              className="account-info-name"
              title={`${userData && userData.username}#${
                userData && userData.discriminator
              }`}
            >
              {userData && userData.username}#
              {userData && userData.discriminator}
            </div>
            {/* <button className="account-info-more">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-more-horizontal"
              >
                <circle cx={12} cy={12} r={1} />
                <circle cx={19} cy={12} r={1} />
                <circle cx={5} cy={12} r={1} />
              </svg>
            </button> */}
          </div>
        </div>
        <div className="app-content">
          <Content />
        </div>
      </div>
    </>
  );
};
export default Dashboard;
