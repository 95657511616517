const Footer = () => {
  return (
    <>
      <footer className="footer-distributed">
        <div className="footer-right">
          <a href="https://discord.gg/recobot">
            <i className="fa-brands fa-discord" />
          </a>
          <a href="mailto:support@recobot.in">
            <i className="fa-solid fa-envelope" />
          </a>
        </div>
        <div className="footer-left">
          <p className="footer-links">
            <a className="link1" href="/">
              Help and Support
            </a>
          </p>
          <p>
            <a href="/">RecoBot.in</a> © 2022
          </p>
        </div>
      </footer>
      <div className="scroll-top">
        <a href="#top">
          <i className="fa-solid fa-arrow-up" />
        </a>
      </div>
    </>
  );
};
export default Footer;
