import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import gif_logo from "../assets/logo.gif";
const Header = () => {
  const [user, setUser] = useState("");
  const [navToggle, setNavToggle] = useState(false);
  let json_user = localStorage.getItem("user");
  function toggleNav() {
    setNavToggle(!navToggle);
  }
  function closeNavToggleState() {
    setNavToggle(false);
  }
  useEffect(() => {
    if (json_user) {
      setUser(JSON.parse(json_user));
    }
  }, []);
  const LoginBtn = () => {
    return (
      <>
        <a
          href="https://discord.com/api/oauth2/authorize?client_id=997116174921912351&redirect_uri=https%3A%2F%2Fserver.recobot.in%2Fapi%2Fdiscord%2Fredirect&response_type=code&scope=identify%20guilds%20guilds.members.read"
          className="nav-option highlight"
        >
          {" "}
          Login
        </a>
      </>
    );
  };
  const DashboardBtn = () => {
    return (
      <>
        <Link
          className={`nav-option highlight`}
          to="/dashboard/profile"
          title="Goto Dashboard"
        >
          {user.username}#{user.discriminator}
        </Link>
      </>
    );
  };
  return (
    <>
      <nav className={navToggle ? "active" : ""}>
        <div className="nav-logo">
          <Link to="/">
            {/* <i className="fa-solid fa-robot logo-img" /> */}
            <img
              src={gif_logo}
              className="logo__img"
              alt="gif_logo"
              srcSet=""
            />
            <span className="logo-text">Reco</span>
          </Link>
        </div>
        <div className="menu-btn" onClick={toggleNav}>
          <i class="fa-solid fa-bars"></i>
        </div>
        <div className="nav-menu">
          <Link onClick={closeNavToggleState} to="/" className="nav-option">
            Home
          </Link>
          <a
            onClick={closeNavToggleState}
            href="#features"
            className="nav-option"
          >
            About
          </a>
          <a onClick={closeNavToggleState} href="#owner" className="nav-option">
            Owner
          </a>
          <a
            onClick={closeNavToggleState}
            href="https://discord.com/invite/9mQvk9eyxJ"
            className="nav-option"
          >
            Server
          </a>
          {user ? <DashboardBtn /> : <LoginBtn />}
        </div>
      </nav>
    </>
  );
};
export default Header;
