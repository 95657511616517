import Dashboard from "./Dashboard";
import axios from "axios";
import { useEffect, useState } from "react";
import { errorHandler, server_link } from "../helpers/auth";
import logo from "../assets/logo.png";
import { FcPositiveDynamic, FcNegativeDynamic } from "react-icons/fc";
import { MdDownloading, MdGeneratingTokens } from "react-icons/md";
import { HiBadgeCheck } from "react-icons/hi";
import { BsWalletFill } from "react-icons/bs";
const DashboardProfile = () => {
  const [userData, setUserData] = useState("");
  const [loading, setLoading] = useState(false);

  const user_id = localStorage.getItem("user_id");
  // //console.log(user_id);
  let get_data = async () => {
    try {
      setLoading(true);
      const link = `${server_link}/api/discord/getuserdata`;
      let res = await axios.post(link, { user_id: user_id });
      if (res.data.success) {
        // //console.log(res);
        setLoading(false);

        setUserData(res.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      errorHandler(error);
    }
  };
  useEffect(() => {
    get_data();
  }, []);

  const Profile = () => {
    const token = userData.user_data;
    const badge = userData.token_data;
    const clientData = JSON.parse(localStorage.getItem("user"));
    // // //console.log(clientData);
    const str = "===>";
    const clip = () => {
      navigator.clipboard.writeText(badge.token);
      window.alert("Token Copied to clipboard");
    };
    return (
      <>
        <div className="__profile">
          <div className="pro__images">
            <img
              className="user__profile"
              src={logo}
              title="Reco#7933"
              alt=""
              srcSet=""
            />
            <div>
              <h1 className="str">{str} </h1>
            </div>
            <img
              className="user__profile"
              src={`https://cdn.discordapp.com/avatars/${clientData.id}/${clientData.avatar}.png`}
              alt=""
              srcSet=""
              title={`${clientData.username}#${clientData.discriminator}`}
            />
          </div>
          <div className="profile__cards">
            <div
              className="profile__card"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="card__img">
                <FcPositiveDynamic />
              </div>
              <div className="card__contents">
                <span className="token__name">Positive Vouches</span>
                <span className="token__value">{token.app_vouches}</span>
              </div>
            </div>

            <div
              className="profile__card"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <div className="card__img pen">
                <MdDownloading />
              </div>
              <div className="card__contents">
                <span className="token__name">Pending Vouches</span>
                <span className="token__value">{token.pen_vouches}</span>
              </div>
            </div>

            <div
              className="profile__card"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="card__img neg">
                <FcNegativeDynamic />
              </div>
              <div className="card__contents">
                <span className="token__name">Negative Vouches</span>
                <span className="token__value">{token.dec_vouches}</span>
              </div>
            </div>

            <div
              data-aos="fade-left"
              data-aos-delay="100"
              onClick={clip}
              className="profile__card cursor"
              title="Click to Copy Token"
            >
              <div className="card__img">
                <MdGeneratingTokens />
              </div>
              <div className="card__contents">
                <span className="token__name">Recovery Token</span>
                <span className="token__name__badge">{badge.token}</span>
              </div>
            </div>

            <div
              className="profile__card"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="card__img">
                <BsWalletFill />
              </div>
              <div className="card__contents">
                <span className="token__name">Overall Vouches</span>
                <span className="token__value">{token.overall_vouches}</span>
              </div>
            </div>

            <div
              className="profile__card"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <div className="card__img pen">
                <HiBadgeCheck />
              </div>
              <div className="card__contents">
                <span className="token__name">Total Badges</span>
                <span className="token__value">{badge.badges.length}</span>
              </div>
            </div>
          </div>

          {/* <table className="table glass___one">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Vouches Name</th>
                <th scope="col">Total Vouches</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Positive Vouches</td>
                <td className="str">{token.app_vouches}</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Pending Vouches</td>
                <td className="str">{token.pen_vouches}</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Negative Vouches</td>
                <td className="str">{token.dec_vouches}</td>
              </tr>
              <tr>
                <th scope="row"></th>
                <td className="str">Overall Vouches</td>
                <td className="str">{token.overall_vouches}</td>
              </tr>
              <tr>
                <th scope="row"></th>
                <td className="str">Total Badges</td>
                <td className="str">{badge.badges.length}</td>
              </tr>
            </tbody>
          </table> */}
        </div>
      </>
    );
  };
  const Content = () => {
    return (
      <>
        <div className="app-content-header">
          <h1 className="app-content-headerText">Profile</h1>
        </div>
        {userData ? (
          <Profile />
        ) : (
          <h3>{!loading ? "No servers found!" : "Loading Data..."}</h3>
        )}
      </>
    );
  };
  return (
    <>
      <Dashboard Content={Content} />
    </>
  );
};

export default DashboardProfile;
