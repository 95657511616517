const LogOut = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("user");
  localStorage.removeItem("user_id");
  window.location.href = "/";
  return (
    <>
      <h3>Loggin out...</h3>
      {/* <Navigate to="/" /> */}
    </>
  );
};
export default LogOut;
