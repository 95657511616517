import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import Login from "./components/Login";

import DashboardServers from "./components/DashboardServers";
import DashboardProfile from "./components/DashboardProfile";
import Redirect from "./components/Redirect";
import Token from "./components/Token";
import DashboardVouches from "./components/DashboardVouches";
import LogOut from "./components/LogOut";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<LogOut />} />
          <Route path="/token/:access_token" element={<Token />} />
          <Route path="/dashboard" element={<Redirect />} />
          <Route path="/dashboard/profile" element={<DashboardProfile />} />
          <Route path="/dashboard/vouches" element={<DashboardVouches />} />

          <Route path="/dashboard/servers" element={<DashboardServers />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
