import Dashboard from "./Dashboard";
import axios from "axios";
import { useEffect, useState } from "react";
import { errorHandler, server_link } from "../helpers/auth";
import logo from "../assets/logo.png";
const DashboardVouches = () => {
  const [userData, setUserData] = useState("");
  const [loading, setLoading] = useState(false);

  const user_id = localStorage.getItem("user_id");
  let get_data = async () => {
    try {
      // //("res");
      setLoading(true);
      const link = `${server_link}/api/discord/getvouchesdata`;
      let res = await axios.post(link, { user_id: user_id });
      if (res.data.success) {
        // //(res);
        setLoading(false);

        setUserData(res.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      errorHandler(error);
    }
  };
  useEffect(() => {
    get_data();
  }, []);
  const Profile = () => {
    const vouch = userData.vouch_data;

    const clientData = JSON.parse(localStorage.getItem("user"));
    // //console.log(clientData);
    const str = "===>";
    return (
      <>
        <div className="__profile">
          <div className="pro__images">
            <img
              className="user__profile"
              src={logo}
              title="Reco#7933"
              alt=""
              srcset=""
            />
            <div>
              <h1 className="str">{str} </h1>
            </div>
            <img
              className="user__profile"
              src={`https://cdn.discordapp.com/avatars/${clientData.id}/${clientData.avatar}.png`}
              alt=""
              srcset=""
              title={`${clientData.username}#${clientData.discriminator}`}
            />
          </div>
          <table className="table glass___one">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>User ID</td>
                <td className="str">{vouch.userID}</td>
              </tr>
              <tr>
                <td>User TAG</td>
                <td className="str">{vouch.userTAG}</td>
              </tr>
              <tr>
                <td>Vouch Number</td>
                <td className="str">{vouch.vouchNum}</td>
              </tr>
              <tr>
                <td>Vouch Comment</td>
                <td className="str">{vouch.comment}</td>
              </tr>
              <tr>
                <td>Vouch Status</td>
                <td className="str">{vouch.Status}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const Content = () => {
    return (
      <>
        <div className="app-content-header">
          <h1 className="app-content-headerText">Vouches</h1>
        </div>
        {userData ? (
          <Profile />
        ) : (
          <h3>{!loading ? "No servers found!" : "Loading Data..."}</h3>
        )}
      </>
    );
  };
  return (
    <>
      <Dashboard Content={Content} />
    </>
  );
};
export default DashboardVouches;
