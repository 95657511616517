const Owner = () => {
  return (
    <>
      <section id="owner">
        <h1 className="section-title">Owner</h1>
        <div className="owner-container">
          <div className="box"  data-aos="fade-right"
                data-aos-delay="100">
            <div className="top-bar" />
            <div className="top">
              <i className="fa fa-check-circle" aria-hidden="true" />
              <input type="checkbox" className="heart-btn" id="heart-btn-1" />
              <label className="heart" htmlFor="heart-btn-1" />
            </div>
            <div className="content">
              <img
                src="https://cdn.discordapp.com/avatars/758961525330411521/a_ba0ff9abc0f94eb63af2113990609aea.gif"
                alt="owner"
                loading="lazy"
              />
              <strong>Owner</strong>
              <p>J8TIN#1111</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Owner;
