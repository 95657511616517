import axios from "axios";
export const server_link = "https://server.recobot.in";
export const errorHandler = (error) => {
  console.log(error);
};
export const getUser = async (access_token) => {
  try {
    // console.log("getUser called");
    if (!access_token) {
      return { success: false, message: "Access token not found!" };
    }
    const url = `${server_link}/api/discord/geuser`;
    console.log(url);
    let response = await axios.post(url, { access_token: access_token });
    if (response.data.success) {
      // console.log(response);
      return response.data;
    }
  } catch (error) {
    errorHandler(error);
    return { success: false, message: "User unauthorised!" };
  }
};
