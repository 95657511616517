import Dashboard from "./Dashboard";
import axios from "axios";
import { useEffect, useState } from "react";
import { errorHandler, server_link } from "../helpers/auth";
import logo from "../assets/logo.png";
import "./dashboard.css";
const DashboardServers = () => {
  const [userData, setUserData] = useState("");
  const [loading, setLoading] = useState(false);
  const access_token = localStorage.getItem("access_token");
  let get_data = async () => {
    try {
      // console.log("res server");
      setLoading(true);
      const link = `${server_link}/api/discord/getguildsdata`;
      let res = await axios.post(link, { access_token: access_token });
      if (res.data.success) {
        // console.log(res);
        setLoading(false);

        setUserData(res.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      errorHandler(error);
    }
  };
  useEffect(() => {
    get_data();
  }, []);

  const Guild = (guild, index) => {
    let guild_icon = `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`;
    const invite = () => {
      const inv_link =
        "https://discord.com/api/oauth2/authorize?client_id=996375131591024690&permissions=140794710080&scope=applications.commands%20bot";
      window.location.href = inv_link;
    };
    return (
      <>
        <div
          data-aos="fade-right"
          data-aos-delay={index * 50}
          className="guild"
          onClick={invite}
          title="Add Reco to the server"
        >
          <img className="guild__icon" src={guild_icon} alt="" />
          <span className="guild__name">{guild.name}</span>
        </div>
      </>
    );
  };
  const Content = () => {
    return (
      <>
        <div className="app-content-header">
          <h1 className="app-content-headerText">Your Servers</h1>
        </div>
        <div className="guilds">
          {userData ? (
            userData.guilds.map(Guild)
          ) : (
            <h3>{!loading ? "No servers found!" : "Loading Data..."}</h3>
          )}
        </div>
      </>
    );
  };
  return (
    <>
      <Dashboard Content={Content} />
    </>
  );
};
export default DashboardServers;
