const Login = () => {
  const link = `https://discord.com/api/oauth2/authorize?client_id=997116174921912351&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fapi%2Fdiscord%2Fredirect&response_type=code&scope=identify%20guilds`;
  window.location.href = link;
  return (
    <>
      <h1>Reco Login</h1>
    </>
  );
};
export default Login;
